/* Define the font face for Helvetica. */
@font-face {
  font-family: 'Helvetica';
  src: url('./fonts/Helvetica.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Define the font face for Helvetica Black Original Bold. */
@font-face {
  font-family: 'Helvetica Black';
  src: url('./fonts/Helvetica-Black.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
}
